<template>
  <v-form
    v-model="isValidData"
    @submit="submit"
  >
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="data.name"
              type="text"
              :rules="requiredFieldRules('Nombre es requerido')"
              outlined
              label="Nombre"
              required
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="red"
        text
        type="button"
        :disabled="creatingWarehousesTypes"
        @click="$emit('close')"
      >
        Cerrar
      </v-btn>
      <v-btn
        type="submit"
        color="success"
        text
        :loading="creatingWarehousesTypes"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'AddWarehousesTypesForm',
    data () {
      return {
        isValidData: false,
        data: {
          name: '',
        },
      }
    },
    computed: {
      ...mapState([
        'user',
        'actionsParentBusiness',
        'creatingWarehousesTypes',
      ]),
      businessId () {
        return (this.user.businessId || this.actionsParentBusiness) || null
      },
    },
    methods: {
      ...mapActions([
        'fetchWarehousesTypes',
        'createWarehousesTypes',
      ]),
      requiredFieldRules (text) {
        return [
          v => !!v || text,
        ]
      },
      resetData () {
        for (const [key] of Object.entries(this.data)) {
          this.data[key] = '' // Making all keys of the object ''
        }
      },
      async afterCreate () {
        this.$emit('close')
        this.resetData()
        await this.fetchWarehousesTypes(this.user.businessId || this.actionsParentBusiness)
      },
      async submit (e) {
        e.preventDefault()
        if (this.isValidData && this.businessId !== null) {
          await this.createWarehousesTypes({
            businessId: this.businessId,
            name: this.data.name,
          })
          this.afterCreate()
        }
      },
    },
  }
</script>
