<template>
  <warehouses-types-table />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import WarehousesTypesTable from '../components/admin-warehouses-types/WarehousesTypesTable'
  export default {
    name: 'AdminWarehousesTypes',
    components: {
      WarehousesTypesTable,
    },
    computed: {
      ...mapState(['user', 'actionsParentBusiness']),
    },
    async created () {
      await this.fetchWarehousesTypes(this.user.businessId || this.actionsParentBusiness)
    },
    methods: {
      ...mapActions([
        'fetchWarehousesTypes',
      ]),
    },
  }
</script>
