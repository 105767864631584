<template>
  <div>
    <v-btn
      v-if="warehouseType.status === 1"
      :disabled="deactivingWarehouseType"
      color="red"
      class="transparent-button-icon"
      icon
      @click="deactiveWarehouseType"
    >
      <v-icon>
        mdi-delete
      </v-icon>
    </v-btn>
    <v-btn
      v-else
      :disabled="activingWarehouseType"
      color="green"
      class="transparent-button-icon"
      icon
      @click="activeWarehouseType_"
    >
      <v-icon>
        mdi-check
      </v-icon>
    </v-btn>
    <v-btn
      color="yellow"
      class="transparent-button-icon"
      icon
      @click="openedEditDialog = true"
    >
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn>
    <edit-warehouses-types-dialog
      v-model="openedEditDialog"
      :warehouse-type="warehouseType"
    />
  </div>
</template>

<script>
  import EditWarehousesTypesDialog from './EditWarehousesTypesDialog'
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'WarehousesTypesActions',
    components: {
      EditWarehousesTypesDialog,
    },
    props: {
      warehouseType: {
        type: Object,
        default () {
          return {}
        },
      },
    },
    data () {
      return {
        openedEditDialog: false,
      }
    },
    computed: {
      ...mapState([
        'deactivingWarehouseType',
        'activingWarehouseType',
        'user',
        'actionsParentBusiness',
      ]),
    },
    methods: {
      ...mapActions([
        'deleteWarehouseType',
        'activeWarehouseType',
        'fetchWarehousesTypes',
      ]),
      async deactiveWarehouseType () {
        await this.deleteWarehouseType({ id: this.warehouseType.id })
        await this.fetchWarehousesTypes(this.user.businessId || this.actionsParentBusiness)
      },
      async activeWarehouseType_ () {
        await this.activeWarehouseType({ id: this.warehouseType.id })
        await this.fetchWarehousesTypes(this.user.businessId || this.actionsParentBusiness)
      },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
